body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/src/assets/fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'Gilroy-Bold';
    src: url('/src/assets/fonts/Gilroy-ExtraBold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
