.marqueeContainer {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  color: #01c2ff;
  text-align: center;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: 96px; /* 100% of 96px */
  text-transform: uppercase;
  background: #000211;
  padding: 1.7rem;
  
}

.marqueeContent {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 600s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.hollow {
  margin-left: 3rem;
  margin-right: 3rem;
  color: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #fff;
}


@keyframes marqueeReverse {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
    }
}

.marqueeContentReverse {
    display: inline-block;
    padding-left: 100%;
    animation: marqueeReverse 600s linear infinite;
}


/* @media (max-width: 768px) {
  .marqueeContainer {
    font-size: 48px; 
    line-height: 48px;
  }
}

@media (max-width: 480px) {
  .marqueeContainer {
    font-size: 32px; 
    line-height: 32px;
  }
}

.marqueeContent, .marqueeContentReverse {
  white-space: nowrap;
  overflow: hidden;
} */