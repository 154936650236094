.videocontainer {
  height: 500px;
  overflow: hidden;
  position: relative;
  /* Fallback background */
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures that all of the video is visible but still covers the container */
  filter: brightness(70%) sepia(100%) hue-rotate(190deg); /* Apply blue filter */
}

.videocontainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    20,
    255,
    0.4
  ); /* Blue color with a high opacity for the tint */
  z-index: 1; /* Ensure it's on top of the video but below any content */
}
