.elevateCard {
	width: 352px;
	height: 380px;
	flex-shrink: 0;
	border-radius: 20px;
	border: 1.5px solid #0169f0;
	background: linear-gradient(
		204deg,
		rgba(14, 43, 131, 0.5) -25.14%,
		rgba(14, 43, 131, 0) 105.79%
	);
	backdrop-filter: blur(13.5px);
	position: relative;
	padding: 20px;
}

.cardIcon {
	position: absolute;
	top: 20px;
	right: 20px;
}

.cardText {
	display: flex;
	width: 250px;
	height: 100px;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	font-family: "Gilroy-Bold", sans-serif;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 130%;
	text-transform: uppercase;
	background: linear-gradient(
		180deg,
		#fff 37.5%,
		rgba(255, 255, 255, 0) 129.78%
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
}

/* For tablets and medium-sized devices */
@media (max-width: 1024px) {
	.elevateCard {
		width: 280px; /* Reduce card width */
		height: 300px; /* Reduce card height */
	}

	.cardIcon {
		top: 15px;
		right: 15px;
	}

	.cardText {
		width: 200px; /* Adjust text width */
		font-size: 28px; /* Slightly decrease font size */
		bottom: 15px; /* Adjust position */
	}
}

/* For mobile devices */
@media (max-width: 768px) {
	.elevateCard {
		width: 220px; /* Further reduce card width */
		height: 240px; /* Further reduce card height */
	}

	.cardIcon {
		top: 10px;
		right: 10px;
	}

	.cardText {
		width: 240px; /* Adjust text width */
		font-size: 24px; /* Further decrease font size */
		bottom: 10px; /* Adjust position */
	}
}

/* For very small mobile devices */
@media (max-width: 480px) {
	.elevateCard {
		width: 240px; /* Further reduce card width */
		height: 200px; /* Further reduce card height */
	}

	.cardIcon {
		width: 80px; /* Adjust icon size */
		height: auto;
		top: 8px;
		right: 8px;
	}

	.cardText {
		width: 150px; /* Adjust text width */
		font-size: 20px; /* Further decrease font size */
		bottom: 8px; /* Adjust position */
	}
}

@media (min-width: 600px) and (max-width: 800px) {
	.cardText {
		width: 120px; /* Adjust text width */
		font-size: 18px; /* Further decrease font size */
		bottom: 6px; /* Adjust position */
	}
	.cardIcon {
		width: 150px; /* Adjust icon size */
	}
}
