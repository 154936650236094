.secondHeading {
  font-family: "Gilroy-Bold", sans-serif;
  padding-left: 6rem;
  padding-right: 6rem;
  text-align: center;
  padding-top: 3.5rem;
  font-size: 54px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  z-index: 2;
  position: relative;
  color: white;
  text-align: center;
}

.parentContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header {
  background: #000211;
}

.mainContainer {
  position: relative;
}
.rightImage {
  content: "";
  position: absolute;
  top: 0;
  right: 0; /* Position to the rightmost side */
  width: 600px; /* Adjust this width based on how wide you want the right image to be */
  height: 100%;
  background-image: url("/src/assets/Vector 12.png"); /* Replace with the path to your new image */
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 0;
}

.leftImage {
  content: "";
  position: absolute;
  top: 0;
  left: 0; /* Position to the rightmost side */
  width: 600px; /* Adjust this width based on how wide you want the right image to be */
  height: 100%;
  background-image: url("/src/assets/Vector13.png"),
    url("/src/assets/Vector14.png"); /* Replace with the path to your new image */
  background-size: cover, cover;
  background-repeat: no-repeat, no-repeat;
  background-color: transparent;
  z-index: 0;
}

/* For tablets and medium-sized devices */
@media (max-width: 1024px) {
  .secondHeading {
    font-size: 48px; /* Slightly decrease font size */
  }

  .parentContainer {
    flex-direction: column;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .rightImage {
    display: none; /* hides the right image on devices with width <= 768px */
  }
  .leftImage {
    display: none;
  }
  .secondHeading {
    font-size: 36px; /* Further decrease font size */
  }

  .parentContainer {
    flex-direction: column;
  }
}

/* For very small mobile devices */
@media (max-width: 480px) {
  .secondHeading {
    font-size: 28px; /* Further decrease font size */
  }

  .parentContainer {
    flex-direction: column;
  }
}



/* General styles */
.box {
  border: 1px solid #01c2ff; /* Your border color */
  border-radius: 8px;
  background: linear-gradient(to bottom, #000211, black);
  padding: 20px;
  margin: 10px 0;
  width: 100%; /* Full width */
  max-width: 300px; /* Maximum width to avoid the box being too wide on large screens */
}

/* Media Query for Medium-sized devices like tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .box {
    max-width: 250px; /* Slightly reduce the maximum width */
  }
}

/* Media Query for Tablets and larger mobile devices */
@media (max-width: 768px) {
  .box {
    max-width: 200px; /* Further reduce the maximum width */
  }
}

