.main {
	display: flex;
	align-items: center; /* To vertically center the items */
	justify-content: space-between; /* To space out the icon and the text */
	align-items: center;
	width: 282.882px;
	height: 96.238px;
	padding: 0.5rem 1rem;
	border-radius: 12px;
	border: 1px solid #192161;
	background: linear-gradient(
		180deg,
		rgba(14, 43, 131, 0.2) 0%,
		rgba(14, 43, 131, 0) 100%
	);
	backdrop-filter: blur(13.5px);
	flex-shrink: 0;
	color: white;
	font-family: Gilroy;
}
.main:hover {
	background: #0169f0;
}

.main:hover .iconSVG path {
	fill: #ffffff; /* Color on hover */
}

.main:hover .iconSVG path {
	fill: #ffffff;
}
