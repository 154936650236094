.secondContainer {
  color: #fff;
  background: #000211;
  font-family: "Gilroy", sans-serif;
  text-align: left;
  padding-left: 50px; 
  position: relative;

}

.secondHeading {
  font-family: "Gilroy-Bold", sans-serif;
  padding-left: 6rem;
  padding-right: 6rem;
  padding-bottom: 4rem;
  padding-top: 3.5rem;
  font-size: 54px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  z-index: 2;
  position: relative;
  width: 800px;
}


/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .secondContainer {
    text-align: center;
    padding-left: 20px;
  }

  .secondHeading {
    font-size: 36px;
    padding-left: 3rem;
    padding-right: 3rem;
    width: auto; /* Let the width be determined by its content */
  }
}

@media (max-width: 480px) {
  .secondHeading {
    font-size: 24px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}