.heroContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background: black;
}
.image1 {
	position: absolute;
	top: 0;
	width: auto;
	height: 40vh;
	z-index: 100;
}
.image2 {
	position: absolute;
	top: 0;
	width: auto;
	height: 90vh;
	mask-image: linear-gradient(to top, transparent, black);
}
.image3 {
	position: absolute;
	top: 0;
	width: 100vw;
	height: 80vh;
}

@media (min-width: 481px) and (max-width: 1002px) {
	.heroContainer {
		padding-bottom: -200px;
	}
}

.heroText {
	z-index: 90;
	text-align: center;
	font-family: "Gilroy-Bold", sans-serif;
	font-size: 110px;
	font-style: normal;
	font-weight: 900;
	line-height: 100%; /* 110px */
	padding: 0rem 5rem;
	text-transform: uppercase;
	background: linear-gradient(
		179deg,
		#fff 75.57%,
		rgba(255, 255, 255, 0) 99.29%
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.comprehensiveText {
	display: flex;
	width: 369px;
	height: 47px;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	color: #fff;
	font-family: "Gilroy", sans-serif;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: center;
	position: absolute;
	bottom: 5rem;
	left: 35vw;
	padding: 10px; /* Optional: You can adjust this padding as per your requirements */
}

/* ... Existing Styles ... */

.heroContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background: black;
}

.heroText {
	text-align: center;
	font-family: "Gilroy-Bold", sans-serif;
	font-size: 110px;
	font-style: normal;
	font-weight: 900;
	line-height: 100%;
	padding: 0rem 5rem;
	text-transform: uppercase;
	background: linear-gradient(
		179deg,
		#fff 75.57%,
		rgba(255, 255, 255, 0) 99.29%
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.comprehensiveText {
	display: flex;
	width: 369px;
	height: 47px;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	color: #fff;
	font-family: "Gilroy", sans-serif;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	position: absolute;
	bottom: 5rem;
	/* left: 3rem; */
	padding: 10px;
}

/* For mobile devices */
@media (max-width: 480px) {
	.image1 {
		height: 30vh;
		width: auto;
	}
	.image2 {
		top: 0vh;
		height: 0vh;
		width: 100%; /* Taking full width of the viewport */
	}
	.image3 {
		top: 20vh;
		height: 40vh;
		width: 100%; /* Taking full width of the viewport */
	}

	.heroContainer {
		overflow: visible;
	}
	.heroText {
		font-size: 44px;
		padding: 0rem 1rem;
	}

	.comprehensiveText {
		font-size: 16px;
		width: auto;
		left: 1rem;
	}
}

/* For tablets */
@media (max-width: 1370px) {
	.comprehensiveText {
		bottom: 12rem;
	}
}
@media (min-width: 480px) and (max-width: 769px) {
	.comprehensiveText {
		left: 35vw !important;
	}
}
@media (max-width: 480px) {
	.comprehensiveText {
		bottom: 11rem;
		margin-left: 15vw;
		padding: 0;
		display: inline-block;
	}
}
@media (min-width: 481px) and (max-width: 768px) {
	.image1 {
		height: 40vh;
		width: auto;
	}
	.image2 {
		top: 20vh;
		height: 35vh;
		width: 100%; /* Taking full width of the viewport */
	}
	.image3 {
		top: 20vh;
		height: 45vh;
		width: 100%; /* Taking full width of the viewport */
	}
	.heroText {
		font-size: 64px;
		padding: 0rem 2rem;
	}

	.comprehensiveText {
		font-size: 18px;
		left: 27vw !important;
	}
}

.image1,
.image2,
.image3 {
	max-width: 100%; /* Ensuring the image doesn't overflow the container */
}

@media (min-width: 769px) and (max-width: 1294px) {
	.comprehensiveText {
		bottom: 0rem;
		left: 30vw;
	}
	.image1 {
		height: 35vh;
	}
}

@media (min-width: 769px) and (max-width: 2189px) {
	.heroText {
		padding: 0rem 3rem;
	}
}

@media (min-width: 1295px) and (max-width: 1370px) {
	.comprehensiveText {
		bottom: 0vh !important;
	}
}

@media (min-width: 1235px) and (max-width: 1245px) and (max-height: 480px) and (min-height: 470px) {
	.comprehensiveText {
		bottom: -6rem !important;
	}
	.heroContainer {
		height: 130vh;
	}
	.heroText {
		font-size: 90px;
		padding: 0rem 4rem;
	}
	.image1 {
		height: 50vh !important;
	}
}
